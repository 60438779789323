<!--
AllPortfoliosMixin
Loads all portfolios available for the current user

Provides: allPortfolios, allPortfoliosReady
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      allPortfolios: 'portfolio/getAll'
    }),
    allPortfoliosReady () {
      return this.allPortfolios !== null
    }
  },
  created () {
    if (this.allPortfolios === null) {
      this.__loadAllPortfolios()
    }
  },
  methods: {
    ...mapActions({
      __loadAllPortfolios: 'portfolio/loadAll'
    })
  }
}
</script>
