<i18n>
{
  "de": {
    "newPortfolio": "Neues Portfolio",
    "noPortfolios": "Keine Portfolios",
    "loading": "Daten werden geladen",
    "buildings_count": "Liegenschaften"
  }
}
</i18n>

<template>
  <li class="portfolio-intro-list-item">
    <router-link :to="{ name: 'portfolio', params: { portfolio_id: portfolio.id } }">
      <section>
        <header>
          <div class="portfolio-id">{{ portfolio.name }}</div>
        </header>
        <main>
          <div class="portfolio-description">
            {{ portfolio.buildings_count }} {{ $t('buildings_count') }}
          </div>
        </main>
      </section>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
.portfolio-intro-list-item {
  display: contents;
}

.portfolio-intro-list-item > a {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.portfolio-intro-list-item > a > section {
  display: contents;
}

.portfolio-intro-list-item header,
.portfolio-intro-list-item main,
.portfolio-intro-list-item footer {
  padding: var(--box-padding);
}

.portfolio-intro-list-item header {
  border-bottom: var(--box-border);
}

.portfolio-intro-list-item .portfolio-id {
  font-weight: 700;
}

.portfolio-intro-list-item main {
  display: grid;
  grid-template-columns: 1fr 50px;
}

.portfolio-intro-list-item .portfolio-description {
  font-size: var(--font-s);
  font-weight: 400;
}

.portfolio-intro-list-item footer {
  align-self: end;
  border-top: var(--box-border);
  font-weight: 400;
}

.portfolio-intro-list-item footer ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: var(--font-xs);
}

.portfolio-intro-list-item footer ul ~ ul{
  margin-top: var(--spacing-xs);
}
</style>
