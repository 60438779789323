<i18n>
{
  "de": {
    "portfolios": "Ihre Portfolios",
    "noPortfolios": "Sie haben keine Portfolios",
    "welcomeTitle": "Willkommen",
    "portfoliosLoading": "Ihre Portfolios werden geladen"
  }
}
</i18n>

<template>
  <main-layout :ready="allPortfoliosReady">
    <template #default>
      <h1 v-if="loggedIn">{{ $t('welcomeTitle') }} {{ username }}!</h1>
      <h2>{{ $t('portfolios') }}</h2>
      <div v-if="!allPortfolios.length">{{ $t('noPortfolios') }}</div>
      <portfolio-intro-list :portfolios="allPortfolios" v-else />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'

import PortfolioIntroList from '@/components/intro/PortfolioIntroList'

import { mapGetters } from 'vuex'

export default {
  mixins: [
    AllPortfoliosMixin // Provides: allPortfolios, allPortfoliosReady
  ],
  computed: {
    ...mapGetters({
      username: 'account/name',
      email: 'account/email',
      company: 'account/company',
      loggedIn: 'account/loggedIn'
    })
  },
  components: {
    MainLayout,
    PortfolioIntroList
  }
}
</script>
