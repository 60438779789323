<template>
  <ul class="portfolio-intro-list">
    <portfolio-intro-list-item v-for="portfolio in portfolios" :portfolio="portfolio" :key="portfolio.id" />
  </ul>
</template>

<script>
import PortfolioIntroListItem from './PortfolioIntroListItem'

export default {
  props: {
    portfolios: {
      type: Array,
      required: true
    }
  },
  components: {
    PortfolioIntroListItem
  }
}
</script>

<style>
.portfolio-intro-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--default-column-width), 1fr));
  grid-gap: var(--spacing-m);
}

.portfolio-intro-list > li > a {
  border-radius: var(--box-radius);
  box-shadow: 0px 0px 4px 0px var(--shadow-color);
}

.portfolio-intro-list li > a:hover {
  box-shadow: 0px 0px 4px 2px var(--shadow-color);
}
</style>
